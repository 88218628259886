import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams, Link } from 'react-router-dom'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { Row, Col } from 'components/ui'
import { getData } from 'common/utils'
import { setErrorHtml } from 'common/slice'
import Button from '@mui/material/Button'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import AlertDialog from 'components/ui/AlertDialog'
import Tree from 'components/ui/Tree'
import IconButton from '@mui/material/IconButton'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import Pagination from '@mui/material/Pagination'
import FolderSharedIcon from '@mui/icons-material/FolderShared'
import { Select } from 'antd'
import Checkbox from '@mui/material/Checkbox'
import 'antd/lib/pagination/style/index.css'
import { toast } from 'react-toastify'
import '../styles/ribbon-item.scss'

const { Option } = Select

const RibbonItem = (props) => {
  const dispatch = useDispatch()
  const queryClient = useQueryClient()
  const { id } = useParams()

  const [dataTree, setDataTree] = useState([])
  const [isOpenAdd, setIsOpenAdd] = useState(false)
  const [isOpenDelete, setIsOpenDelete] = useState(false)
  const [isOpenMultiDelete, setIsOpenMultiDelete] = useState(false)
  const [isSelectAll, setIsSelectAll] = useState(false)
  const [idDelete, setIdDelete] = useState(null)
  const [vodList, setVodList] = useState([])
  const [vod, setVod] = useState(null)

  const [ordList, setOrdList] = useState([])

  // eslint-disable-next-line no-unused-vars
  const [total, setTotal] = useState(0)
  const [totalPage, setTotalPage] = useState(1)
  const [limitData, setLimitData] = useState(10)
  const [current, setCurrent] = useState(1)
  const [offset, setOffset] = useState(0)

  const [searchText, setSearchText] = useState('')

  const pageList = [
    { value: 10, label: '10 / page' },
    { value: 20, label: '20 / page' },
    { value: 50, label: '50 / page' }
  ]

  const { data: dataVodList } = useQuery(['getRibbonItemVodEntities', searchText], async () => {
    if (searchText !== '') {
      return await getData('GET', `tenants/${localStorage.getItem('tenant')}/vod-entities/?q=${searchText}`)
    } else {
      return {
        data: {
          results: []
        }
      }
    }
  })

  const { data: dataRibbonItem } = useQuery(['getRibbonItem', id, limitData, offset], async () => {
    if (id) {
      return await getData('GET', `ribbons/${id}/ribbon-items/?limit=${limitData}&offset=${offset}`)
    }
  })

  const mutation = useMutation(async (dataForm) => {
    if (dataForm) {
      return await getData('POST', 'ribbon-items/', dataForm)
    }
  }, {
    onSuccess: () => {
      setSearchText('')
      setVod(null)
      queryClient.invalidateQueries('getRibbonItem')
    }
  })

  const mutationDelete = useMutation(async (id) => {
    if (id) {
      return await getData('DELETE', `ribbon-items/${id}/`)
    }
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries('getRibbonItem')
    }
  })

  const mutationMultipleDelete = useMutation(async (id) => {
    if (id) {
      return await getData('DELETE', `ribbon-items/remove-items?${id}`)
    }
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries('getRibbonItem')
    }
  })

  const mutationUpdateOrder = useMutation(async (dataForm) => {
    if (dataForm && id) {
      return await getData('PUT', `ribbons/${id}/ribbon-items-order/`, dataForm)
    }
  }, {
    onSuccess: () => {
      setOffset(0)
      setCurrent(1)
      queryClient.invalidateQueries('getRibbonItem')
    }
  })

  useEffect(() => {
    if (dataVodList) {
      const resultData = dataVodList.data && dataVodList.data.results

      if (Array.isArray(resultData)) {
        const selectData = resultData.map(item => {
          return {
            value: item.id,
            label: (item.type === 4 || item.type === 6) ? item.episode_name : item.title
          }
        })
        setVodList(selectData)
      }
    }
  }, [dataVodList])

  useEffect(() => {
    if (dataRibbonItem) {
      const resultData = dataRibbonItem.data && dataRibbonItem.data.results

      if (Array.isArray(resultData)) {
        setTotal(dataRibbonItem.data?.total)
        setTotalPage(Math.ceil(dataRibbonItem.data?.total / limitData))

        const dataTemp = resultData.map((item, index) => {
          const typeValue = item.entity && item.entity.type

          return {
            key: `0-${index}`,
            indexValue: index,
            ord: item.ord,
            id: item.id,
            isSelected: false,
            entityId: item.entity && item.entity.id,
            title: item.entity && item.entity.title,
            sharedContent: item?.share_content || undefined,
            type: typeValue === 1
              ? 'Single movie'
              : typeValue === 2
                ? 'Show/Drama'
                : typeValue === 3
                  ? 'Season'
                  : typeValue === 4
                    ? 'Episode'
                    : typeValue === 5
                      ? 'CHANNEL'
                      : typeValue === 6
                        ? 'TRAILER'
                        : typeValue === 7
                          ? 'DOCUMENT'
                          : typeValue === 8
                            ? 'BREAKING NEWS'
                            : typeValue === 9
                              ? 'AUDIO'
                              : typeValue === 10
                                ? 'EVENT'
                                : typeValue === 11
                                  ? 'VERTICAL VIDEO'
                                  : typeValue === 12
                                    ? 'SHOW AUDIO'
                                    : typeValue === 17
                                      ? 'MULTI NEWSPAPER'
                                      : typeValue === 18
                                        ? 'EMAGAZINE'
                                        : typeValue === 19
                                          ? 'INTERNAL NEWS'
                                          : 'Unknown'
          }
        })

        const ordTemp = dataTemp.map(item => item.ord)
        setOrdList(ordTemp)

        setDataTree(dataTemp)
        // setExpandedKeys(['0-0', '0-0-0', '0-0-0-0'])
      }
    }
  }, [dataRibbonItem])

  /* ----------------------------------------------- */
  /* --------------- Handle Event ------------------ */
  /* ----------------------------------------------- */
  const onDragEnter = info => {
    // console.log(info)
  }

  const onDrop = info => {
    const dropKey = info.node.key
    const dragKey = info.dragNode.key
    const dropPos = info.node.pos.split('-')
    const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1])

    const loop = (data, key, callback) => {
      for (let i = 0; i < data.length; i++) {
        if (data[i].key === key) {
          return callback(data[i], i, data)
        }
        if (data[i].children) {
          loop(data[i].children, key, callback)
        }
      }
    }
    const data = [...dataTree]

    // Find dragObject
    let dragObj
    loop(data, dragKey, (item, index, arr) => {
      arr.splice(index, 1)
      dragObj = item
    })

    if (!info.dropToGap) {
      // // Drop on the content
      // loop(data, dropKey, item => {
      //   item.children = item.children || []
      //   // where to insert 示例添加到头部，可以是随意位置
      //   item.children.unshift(dragObj)
      // })
      let ar
      let i
      loop(data, dropKey, (item, index, arr) => {
        ar = arr
        i = index
      })
      if (dropPosition === -1) {
        ar.splice(i, 0, dragObj)
      } else {
        ar.splice(i + 1, 0, dragObj)
      }
    } else if (
      (info.node.props.children || []).length > 0 && // Has children
      info.node.props.expanded && // Is expanded
      dropPosition === 1 // On the bottom gap
    ) {
      loop(data, dropKey, item => {
        item.children = item.children || []
        // where to insert 示例添加到头部，可以是随意位置
        item.children.unshift(dragObj)
        // in previous version, we use item.children.push(dragObj) to insert the
        // item to the tail of the children
      })
    } else {
      let ar
      let i
      loop(data, dropKey, (item, index, arr) => {
        ar = arr
        i = index
      })
      if (dropPosition === -1) {
        ar.splice(i, 0, dragObj)
      } else {
        ar.splice(i + 1, 0, dragObj)
      }
    }

    setDataTree(data)
  }

  const handleInputChange = (e, value) => {
    setSearchText(value)
  }

  const handleCloseAdd = () => {
    setIsOpenAdd(false)
  }

  const handleClickAddToRibbon = () => {
    if (vod) {
      setIsOpenAdd(true)
    } else {
      toast.warning('Please select content', {
        hideProgressBar: true,
        autoClose: 2000,
        position: 'top-right'
      })
    }
  }

  const handleSaveAdd = async () => {
    try {
      await mutation.mutateAsync({
        // ord: 0,
        ribbon: id,
        entity: vod && vod.value,
        tenant: localStorage.getItem('tenant')
      })

      setIsOpenAdd(false)
      toast.success('Entity added successfully')
    } catch (err) {
      setIsOpenAdd(false)
      if (err.response) {
        dispatch(setErrorHtml(err.response.data))
      }

      toast.error('Something went wrong')
      console.log(err)
    }
  }

  const handleChangeContent = (e, value) => {
    setVod(value)
  }

  const handleClickShowModalDelete = (e, record) => {
    setIdDelete(record.id)
    setIsOpenDelete(true)
  }

  const handleClickShowModalMultiDelete = () => {
    setIsOpenMultiDelete(true)
  }

  const handleCloseDelete = () => {
    setIsOpenDelete(false)
  }

  const handleCloseMultiDelete = () => {
    setIsOpenMultiDelete(false)
  }

  const handleClickDelete = async () => {
    try {
      await mutationDelete.mutateAsync(idDelete)
      setIsOpenDelete(false)

      toast.success('Entity deleted successfully')
    } catch (err) {
      setIsOpenDelete(false)
      toast.error('Something went wrong!')
      console.log(err)
    }
  }

  const handleClickUpdateOrder = async () => {
    try {
      const arrTemp = []
      let indexTemp = 1

      for (let i = 0; i < dataTree.length; i++) {
        const itemId = dataTree[i] && dataTree[i].id

        arrTemp.push({
          id: itemId,
          ord: indexTemp
        })

        indexTemp += 1
      }
      await mutationUpdateOrder.mutateAsync(arrTemp)

      toast.success('Update order successfully')
    } catch (err) {
      console.log(err)
    }
  }

  const handleClickUpdateOrderByNumber = async () => {
    try {
      const arrTemp = []
      // let indexTemp = 1

      for (let i = 0; i < ordList.length; i++) {
        const itemId = dataTree[i] && dataTree[i].id

        arrTemp.push({
          id: itemId,
          ord: ordList[i]
        })

        // indexTemp += 1
      }
      await mutationUpdateOrder.mutateAsync(arrTemp)

      toast.success('Update order successfully')
    } catch (err) {
      console.log(err)
    }
  }

  const handleChangePage = (event, value) => {
    setCurrent(value)
    setOffset((value - 1) * limitData)
  }

  const handleChangeLimit = (value) => {
    setLimitData(value)
    setCurrent(0)
    setOffset(0)
  }

  const handleOnChangeCheckbox = (e, position) => {
    const dataClone = [...dataTree]
    const updatedCheckedState = dataClone.map((item, index) =>
      index === position
        ? {
            ...item,
            isSelected: !item.isSelected
          }
        : {
            ...item,
            isSelected: item.isSelected
          }
    )

    setDataTree(updatedCheckedState)
  }

  const handleClickMultiple = async () => {
    let strTemp = ''

    for (let i = 0; i < dataTree.length; i++) {
      if (dataTree[i].isSelected === true) {
        strTemp += 'id=' + dataTree[i]?.id + '&'
      }
    }

    if (strTemp.slice(-1) === '&') {
      strTemp = strTemp.slice(0, -1)
    }

    try {
      await mutationMultipleDelete.mutateAsync(strTemp)

      setIsOpenMultiDelete(false)
      toast.success('Entity deleted successfully')
    } catch (err) {
      setIsOpenMultiDelete(false)
      if (err.response) {
        dispatch(setErrorHtml(err.response.data))
      }

      toast.error('Something went wrong!')
      console.log(err)
    }
  }

  const handleSelectAll = () => {
    const dataClone = [...dataTree]
    const updatedCheckedState = dataClone.map((item, index) => {
      return {
        ...item,
        isSelected: !isSelectAll
      }
    })

    setDataTree(updatedCheckedState)
    setIsSelectAll(!isSelectAll)
  }

  const handleChangeOrd = (e, index) => {
    const valueTemp = e.target.value
    const arrTemp = [...ordList]
    arrTemp[index] = valueTemp
    setOrdList(arrTemp)
  }

  return (
    <div className="ribbon-item">
      <Row className="pt-3 pb-4">
        <Col md={9}>
          <Autocomplete
            disablePortal
            fullWidth
            value={vod}
            onChange={handleChangeContent}
            size="small"
            options={vodList}
            onInputChange={handleInputChange}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            disableClearable
            renderInput={(params) =>
              <TextField
                {...params}
                label="Content"
              />
            }
          />
        </Col>
        <Col md={3}>
          <Button variant="contained" className="float-right" onClick={handleClickAddToRibbon}>Add To Ribbon</Button>
        </Col>
      </Row>

      <Row className="pb-2">
        <Col md={12}>
          {(Array.isArray(dataTree) && dataTree.length > 0) &&
            <>
              <Checkbox
                style={{
                  marginLeft: '53px'
                }}
                checked={isSelectAll}
                onClick={handleSelectAll}
              />

              {dataTree.every(v => v.isSelected === false)
                ? <></>
                : <Button variant="contained" className="float-right" color="error" onClick={handleClickShowModalMultiDelete} startIcon={<DeleteIcon />}>
                    Delete
                  </Button>
              }
            </>
          }
        </Col>
      </Row>

      <Row>
        <Col md={12}>
          {id &&
            <Tree
              className="draggable-tree"
              // expandedKeys={expandedKeys}
              draggable
              blockNode
              onDragEnter={onDragEnter}
              onDrop={onDrop}
              treeData={dataTree}
              titleRender={(nodeData) => {
                return (
                  <Row>
                    <Col md={2}>
                      <Checkbox
                        checked={dataTree[nodeData.indexValue]?.isSelected}
                        onClick={(e) => handleOnChangeCheckbox(e, nodeData.indexValue)}
                      />
                      <TextField
                        size="small"
                        value={ordList[nodeData.indexValue]}
                        onChange={e => handleChangeOrd(e, nodeData.indexValue)}
                        variant="filled"
                        inputProps={{ style: { fontSize: 14, textAlign: 'center' } }}
                        style={{
                          width: '50px',
                          marginLeft: '20px'
                        }}
                      />
                    </Col>
                    <Col md={7}>
                      {nodeData.url
                        ? <>
                            <img className="d-inline-block float-left" src={nodeData.url} width={60} height={50} />
                            <div className="d-inline-block pl-4 pt-2 float-left">{nodeData.title}</div>
                          </>
                        : <span>{nodeData.type} : {nodeData.title}</span>
                      }
                    </Col>
                    <Col md={3}>
                      <div className="float-right mr-4 ribbon-item-delete" onClick={e => handleClickShowModalDelete(e, nodeData)}>
                        <IconButton color="error">
                          <DeleteIcon />
                        </IconButton>
                        Delete
                      </div>
                      {
                        nodeData?.sharedContent
                          ? <Link to={`/share-with-me/?q=${nodeData.title}`} className="ribbon-item-edit float-right mr-4">
                            <IconButton color="success">
                              <FolderSharedIcon />
                            </IconButton>
                            Shared
                          </Link>
                          : <Link to={`/entityvod/${nodeData.entityId}`} className="ribbon-item-edit float-right mr-4">
                            <IconButton color="success">
                              <EditIcon />
                            </IconButton>
                            Edit
                          </Link>
                      }
                    </Col>
                  </Row>
                )
              }}
            />
          }
        </Col>
      </Row>

      {(dataTree && dataTree.length > 0) &&
        <Row className="pt-4">
          <Col md={4}>
            <div style={{ display: 'flex' }}>
              <Button fullWidth variant="contained" onClick={handleClickUpdateOrder}>
                Update Order
              </Button>
              <Button fullWidth variant="contained" onClick={handleClickUpdateOrderByNumber} style={{ marginLeft: '20px' }}>
                Update Order By Number
              </Button>
            </div>
          </Col>
          <Col md={8}>
            <Select className="float-right ml-4" value={limitData} onChange={handleChangeLimit} style={{ width: 110 }}>
              {pageList && pageList.map((item, index) => {
                return (
                  <Option key={index} value={item.value}>{item.label}</Option>
                )
              })}
            </Select>

            <Pagination
              className="float-right"
              count={totalPage}
              page={current}
              onChange={handleChangePage}
              showFirstButton
              showLastButton
              variant="outlined"
              shape="rounded"
              color="standard"
            />
          </Col>
        </Row>
      }

      <AlertDialog
        isOpen={isOpenAdd}
        title="Notification"
        contentText="Are you sure you want to add this item?"
        onClose={handleCloseAdd}
        onSave={handleSaveAdd}
      />

      <AlertDialog
        isOpen={isOpenDelete}
        type="delete"
        title="Notification"
        contentText="This action cannot be undone. Are you sure you want to delete this item?"
        onClose={handleCloseDelete}
        onSave={handleClickDelete}
      />

      <AlertDialog
        isOpen={isOpenMultiDelete}
        type="delete"
        title="Notification"
        contentText="This action cannot be undone. Are you sure you want to delete this item?"
        onClose={handleCloseMultiDelete}
        onSave={handleClickMultiple}
      />
    </div>
  )
}

export default RibbonItem
